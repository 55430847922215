import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa'; // FontAwesome icons
import AnimatedButton from './AnimatedButton';
import '../styles/Header.css';
import Cookies from 'js-cookie';
import axios from "axios";


const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;
  const [isAdmin, setIsAdmin] = useState(false);
    
  useEffect(() => {
    const checkIsAdmin = async () => {
      const token = Cookies.get('token');
      console.log(token);
      
      if (token) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/admin/dashboard`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          console.log(response.data);

          if (response.data.success === true) {
            return true;
          }
        } catch (error) {
          console.error(error);
          return false;
        }
      }
      return false;
    };

    // Call the async function and set the state based on its result
    const fetchData = async () => {
      const isAdmin = await checkIsAdmin();
      setIsAdmin(isAdmin);
    };
    fetchData(); // Trigger the async check
  }, []);

  const closeMenu = () => {
    setIsOpen(false);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    toggleMenu();
  };

  return (
    <><header className="fixed top-0 left-0 w-full bg-white-100 shadow z-50 font-inter">
<div className={`w-full md:px-16 px-4 py-4 flex ${isAdmin ? 'justify-between' : 'justify-between items-center'}`}>
<h1 className="text-2xl font-bold">
                  <Link to="/" aria-label="Glass and Grit Home">
                      <img src="/images/logo_5.png" alt="Glass and Grit Logo" className="h-16" />
                  </Link>
              </h1>
              <div className="md:hidden flex items-center">
                  <button onClick={toggleMenu} aria-label="Toggle Menu">
                      {isOpen ? <FaTimes className="text-3xl" /> : <FaBars className="text-3xl" />}
                  </button>
              </div>
              <div className="hidden md:flex text-gray-800 font-bold">
                  <ul className="flex space-x-4 no-underline">
                    {isAdmin ? (
                      <li className=' flex justify-center items-center'>
                        <Link to="/admin/dashboard" className="hover:text-cerulean-600 transition duration-300 ease-in-out" aria-label="Dashboard">
                            Dashboard
                        </Link>
                      </li>
                    ) : (  
                                <><li>
                                  {currentPath === '/' || currentPath === '' ? (
                                      <button
                                          onClick={() => scrollToSection('home')}
                                          className="hover:text-cerulean-600 transition duration-300 ease-in-out"
                                          aria-label="Home">
                                          Home
                                      </button>

                                  ) : (
                                      <Link to="/" className="hover:text-cerulean-600 transition duration-300 ease-in-out" aria-label="Home">
                                          Home
                                      </Link>
                                  )}
                              </li><li>
                                      {currentPath === '/' || currentPath === '' ? (
                                          <button
                                              onClick={() => scrollToSection('services')}
                                              className="hover:text-cerulean-600 transition duration-300 ease-in-out"
                                              aria-label="Services">
                                              Services
                                          </button>
                                      ) : (
                                          <Link to="/services" className="hover:text-cerulean-600 transition duration-300 ease-in-out" aria-label="Home">
                                              Services
                                          </Link>
                                      )}
                                  </li><li>
                                      <Link to="/prices" className=" hover:text-cerulean-600 transition duration-300 ease-in-out" aria-label="Prices">
                                          Prices
                                      </Link>
                                  </li><li>
                                      <Link to="/contact" className="hover:text-cerulean-600 transition duration-300 ease-in-out" aria-label="Contact Us">
                                          Contact
                                      </Link>
                                  </li><li>
                                      <Link to="/about" className="hover:text-cerulean-600 transition duration-300 ease-in-out" aria-label="About Us">
                                          About
                                      </Link>
                                  </li>
                                {currentPath === '' || currentPath === '/' && (
                                    <li>
                                        <button
                                            onClick={() => scrollToSection('faq')}
                                            className="hover:text-cerulean-600 transition duration-300 ease-in-out"
                                            aria-label="FAQ">
                                            FAQ
                                        </button>
                                    </li>
                                    )}</>
                                )}
                  </ul>
              </div>
              {!isAdmin && (
              <div className="block">
                <Link to="/contact">                
                        <AnimatedButton
                      text="Get a Quote"
                      type="button"
                      className="bg-cerulean-600 text-white-50 font-bold py-3 px-4 rounded"
                      aria-label="Get a Quote" />
                </Link>
              </div>
              )}
          </div>
          {isOpen && (
              <div className="md:hidden px-4 py-4 bg-white-100 bg-opacity-100 text-gray-800 font-bold flex flex-col justify-center items-center">
                  <ul className="flex flex-col space-y-4 ">
                    {isAdmin ? (
                      <li className='text-center'>
                        <Link to="/admin/dashboard" className="hover:text-cerulean-600 transition duration-300 ease-in-out" onClick={toggleMenu} aria-label="Dashboard">
                            Dashboard
                        </Link>
                      </li>
                    ) : (
                      <> 
                      <li className='text-center'>
                          <Link to="/" className="hover:text-cerulean-600 transition duration-300 ease-in-out" onClick={toggleMenu} aria-label="Home">
                              Home
                          </Link>
                      </li>
                      <li className='text-center'>
                        <button
                            onClick={() => scrollToSection('services')}
                            className="hover:text-cerulean-600 transition duration-300 ease-in-out"
                            aria-label="Services">
                        Services
                        </button>
                      </li>
                      <li className='text-center'>
                                      <Link to="/prices" className="hover:text-cerulean-600 transition duration-300 ease-in-out" aria-label="Prices">
                                          Prices
                                      </Link>
                                  </li>
                      <li className='text-center'>
                          <Link to="/contact" className="hover:text-cerulean-600 transition duration-300 ease-in-out" onClick={toggleMenu} aria-label="Contact Us">
                              Contact
                          </Link>
                      </li>
                      <li className='text-center'>
                          <Link to="/about" className="hover:text-cerulean-600 transition duration-300 ease-in-out" onClick={toggleMenu} aria-label="About Us">
                              About
                          </Link>
                      </li>
                      {currentPath === '' || currentPath === '/' && (
                         <li className='text-center'>
                            <button
                                onClick={() => scrollToSection('faq')}
                                className="hover:text-cerulean-600 transition duration-300 ease-in-out"
                                aria-label="FAQ">
                                FAQ
                            </button>
                        </li>
                      )}</>
                      )}
                  </ul>
              </div>
          )}
        <div className="h-1 bg-cerulean-600"></div>
      </header>
      </>
  );
};

export default Header;
