import React, { useState, useEffect } from "react";    
import { useNavigate } from "react-router-dom";
import axios from "axios";

const RegisterPage = () => {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [key, setKey] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        if (isSuccess) {
            const timer = setTimeout(() => {
                navigate('/admin/login'); // Redirect to the dashboard
            }, 5000);
            return () => clearTimeout(timer); // Cleanup the timer on component unmount
        }
    }, [isSuccess, navigate]);

    const HandleRegister = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/admin/register`, { username, email, password, key });
            if (response.data.success) {
                setIsSuccess(true);
                setResponseMessage(response.data.message);
            } else {
                setResponseMessage(response.data.message);
            }
        } catch (error) {
            console.log(error);
            setResponseMessage('There was an error during registration. Please try again.');
        }
    };

    return (
        <div className="min-h-screen bg-white-50 flex flex-col justify-start items-center mt-24 pb-12" 
            style={{
                backgroundImage: `url('/images/populationbackground.jpg')`, 
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}>
            <div className="mt-12 w-1/2 bg-white-50 h-full min-h-[400px] min-w-[380px] flex flex-col rounded hover:shadow-xl hover:scale-105 transition duration-200 ">
                <h2 className="text-3xl font-bold mb-4 text-center pt-4">Register</h2>
                <div className="h-1 w-full bg-cerulean-600"></div>
                <div className="flex flex-col justify-center items-center mt-4">
                    <input id="username" type="text" placeholder="Username" className="p-2 w-3/4 bg-white-50 mb-4 rounded border-2" onChange={(e) => setUsername(e.target.value)}/>
                    <input id="email" type="text" placeholder="email" className="p-2 w-3/4 bg-white-50 mb-4 rounded border-2" onChange={(e) => setEmail(e.target.value)}/>
                    <input id="password" type="password" placeholder="Password" className="p-2 w-3/4 bg-white-50 mb-4 rounded border-2" onChange={(e) => setPassword(e.target.value)}/>
                    <input id="key" type="text" placeholder="Key" className="p-2 w-3/4 bg-white-50 mb-4 rounded border-2" onChange={(e) => setKey(e.target.value)}/>
                    <button onClick={HandleRegister} className="bg-cerulean-600 text-white-50 font-bold py-3 px-6 rounded w-3/4 hover:bg-cerulean-700 transition duration-200">Register</button>
                </div>
                {responseMessage && (
                    <div className={`mt-4 p-2 text-center text-white font-bold ${isSuccess ? 'bg-green-500' : 'bg-red-500'} rounded`}>
                        {responseMessage}
                    </div>
                )}
            </div>
        </div>
    );
}

export default RegisterPage;
        