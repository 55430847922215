    import React, { useState, useEffect } from 'react';
    import { useNavigate, useParams } from 'react-router-dom';
    import Cookies from 'js-cookie';
    import axios from "axios";
    import LoadingSpinner from "../components/LoadingSpinner";

    const ViewJobPage = () => {
        const [loading, setLoading] = useState(true);
        const { id } = useParams();
        const [jobData, setJobData] = useState({
            jobName: '',
            name: '',
            address: '',
            phoneNumber: '',
            email: '',
            services: '',
            date: '',
            notes: '',
            status: 'pending'
            });
        const navigate = useNavigate();
        const [error, setError] = useState('');

        useEffect(() => {
            const fetchJob = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/admin/jobs/${id}`, {
                        headers: {
                            Authorization: `Bearer ${Cookies.get('token')}`
                        }
                    });
    
                    if (response.data.success) {
                        setJobData({
                            jobName: response.data.job.jobName,
                            name: response.data.job.name,
                            address: response.data.job.address,
                            phoneNumber: response.data.job.phoneNumber,
                            email: response.data.job.email,
                            services: response.data.job.services.join(','),
                            date: new Date(response.data.job.date).toISOString().substring(0, 10),
                            notes: response.data.job.notes,
                            status: response.data.job.status
                        });
                    }
                } catch (error) {
                    console.error('Error fetching job data:', error);
                    navigate('/admin/dashboard');
                } finally {
                    setLoading(false);
                }
            };
    
            fetchJob();
        }, [id]);

        return (
           <>{loading ? (
            <div className="min-h-screen flex justify-center flex-col w-full items-center mt-32 mb-12 ">
                <LoadingSpinner />
            </div>
            ) : (    
            <div>       
                <div className="min-h-screen bg-gray-100 flex flex-col items-center mt-24 p-8">
                <div className="w-full max-w-4xl bg-white-50 shadow-lg rounded p-8">
                <h1 className="text-2xl font-bold mb-4">{jobData.jobName}</h1>

                    <div className="text-gray-600 space-y-3">
                        <p><strong className="font-semibold">Name:</strong> {jobData.name}</p>
                        <p><strong className="font-semibold">Address:</strong> {jobData.address}</p>
                        <p><strong className="font-semibold">Phone Number:</strong> {jobData.phoneNumber}</p>
                        <p><strong className="font-semibold">Email:</strong> {jobData.email}</p>
                        <p><strong className="font-semibold">Services:</strong> {jobData.services}</p>
                        <p><strong className="font-semibold">Date:</strong> {jobData.date}</p>
                        <p><strong className="font-semibold">Status:</strong> {jobData.status}</p>
                        <p><strong className="font-semibold">Notes:</strong> {jobData.notes}</p>
                    </div>

                    <div className="mt-6 flex space-x-4 justify-end">
                        <button
                            onClick={() => navigate(`/admin/edit-job/${id}`)}
                            className="bg-blue-600 text-white-50 px-6 py-2 rounded-lg hover:bg-blue-700 transition duration-200"
                        >
                            Edit
                        </button>

                        <button
                            onClick={() => navigate(`/admin/dashboard`)}
                            className="bg-gray-600 text-white-50 px-6 py-2 rounded-lg hover:bg-gray-700 transition duration-200"
                        >
                            Dashboard
                        </button>
                    </div>
                </div>
                </div>
            </div>
            )}</>
        );
    };
    
    export default ViewJobPage;