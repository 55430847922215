import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ServicesPage from './pages/ServicesPage';
import PressureWashingPage from './pages/PressureWashingPage';
import WindowCleaningPage from './pages/WindowCleaningPage';
import ContactPage from './pages/ContactPage';
import AboutUsPage from './pages/AboutUsPage';
import FaqPage from './pages/FaqPage';
import NotFoundPage from './pages/NotFoundPage';
import Header from './components/Header';
import Footer from './components/Footer';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import MessagePage from './pages/MessagePage';
import RegisterPage from './pages/RegisterPage';
import CreateJob from './pages/createJob';
import EditJob from './pages/editJob';
import ViewJobPage from './pages/ViewJobPage';
import PricePage from './pages/PricePage';
function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/services/pressure-washing" element={<PressureWashingPage />} />
          <Route path="/services/window-cleaning" element={<WindowCleaningPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/prices" element={<PricePage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/admin/login" element={<LoginPage />} />
          <Route path="/admin/register" element={<RegisterPage />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/messages/:id" element={<MessagePage />} />
          <Route path="/admin/create-job" element={<CreateJob />} />
          <Route path="/admin/edit-job/:id" element={<EditJob />} />
          <Route path="/admin/view-job/:id" element={<ViewJobPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
