import React, { useEffect } from "react";
import { FaUsers, FaHandsHelping, FaBullseye } from "react-icons/fa"; // Import any icons you want to use
import Slider from "react-slick"; // Ensure react-slick and slick-carousel are installed
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AboutUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const teamMembers = [
    {
      name: "Chaz Fultz",
      position: "Owner",
      image: "/images/window1.jpg", // Replace with actual images
      description: "With over 5 years of experience in the cleaning industry, Chaz leads our team with passion and integrity.",
      icon: <FaUsers className="text-cerulean-400 text-3xl mb-2" />,
    },
    {
      name: "Caleb Groudle",
      position: "Owner",
      image: "/images/window2.jpg", // Replace with actual images
      description: "He brings a wealth of knowledge and expertise to our team.",
      icon: <FaHandsHelping className="text-cerulean-400 text-3xl mb-2" />,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div
      className="w-full px-8 py-12 h-auto min-h-screen flex flex-col align-middle justify-start mt-24 bg-gray-100"
      style={{
        backgroundImage: `url('/images/populationbackground.jpg')`, // Replace with actual background image
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-16">
        About Us
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {teamMembers.map((member, index) => (
          <div
            key={index}
            className="bg-white-50 p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105"
          >
            <div className="flex justify-center mb-4">{member.icon}</div>
            {/* <img
              src={member.image}
              alt={`${member.name} Profile`}
              className="w-full h-48 object-cover rounded mb-4"
            /> */}
            <h2 className="text-2xl font-semibold text-center mb-2">
              {member.name}
            </h2>
            <h3 className="text-lg text-center text-gray-600 mb-4">
              {member.position}
            </h3>
            <p className="text-gray-700 mb-4">{member.description}</p>
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-12">
        <div className="h-1 w-3/4 bg-cerulean-600"></div>
      </div>
      <div className="mt-12 bg-white-50 p-6 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
          Our Mission
        </h2>
        <p className="text-gray-700 text-center max-w-2xl mx-auto mb-8">
          At Glass and Grit, we are committed to providing exceptional cleaning
          services that exceed our clients' expectations. Our team is dedicated
          to using eco-friendly products and techniques to ensure a clean and
          safe environment for our customers and the planet.
        </p>
        <Slider {...settings}>
          <div>
            <img
              src="/images/pressurewashing1.jpg" // Replace with actual mission image
              alt="Mission Image 1"
              className="w-full h-72 object-cover rounded"
            />
          </div>
          <div>
            <img
              src="/images/pressurewashing2.jpg" // Replace with actual mission image
              alt="Mission Image 2"
              className="w-full h-72 object-cover rounded"
            />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default AboutUsPage;
