import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import AnimatedButton from './AnimatedButton';
import { Link } from 'react-router-dom';
const HeroSection = () => {

  return (
    <div id='home' className="relative w-full h-[90vh] overflow-hidden">
      <img
        src="images/house4.png"
        alt="Glass and Grit Logo"
        className="w-full h-[90vh] object-cover"
      />
      <motion.div
        className="absolute inset-0 flex flex-col justify-center items-center text-center text-white-50 p-4 shadow"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }} 
        transition={{ duration: 0.8 }}
      >
        <h1 className="text-4xl md:text-6xl font-bold mb-4">
          Welcome to <span className="text-cerulean-600">Glass <a className='text-white-50'>and</a> Grit Precision Cleaning</span>
        </h1>
        <p className="text-lg md:text-xl mb-6">
          Your trusted partner for{' '}
          <a className="text-cerulean-600 font-bold">pressure washing</a> and{' '}
          <a className="text-cerulean-600 font-bold">outdoor cleaning</a> services.
        </p>
        <Link to="/contact">                
          <AnimatedButton
            text="Book Now"
            type="button"
            className="bg-cerulean-700 text-white-50 font-bold py-3 px-6 rounded"
            aria-label="Book Now"
          />
        </Link>
      </motion.div>
    </div>
  );
};

export default HeroSection;
