import React from "react";
import ContactForm from "../components/ContactForm";
function ContactPage() {
    return (
        <div className="min-h-auto bg-white-50 flex flex-col justify-start items-center mt-24 pb-12" style={{
            backgroundImage: `url('/images/populationbackground.jpg')`, // Replace with your image path
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
        }}>
            <ContactForm />
        </div>
    );
}   

export default ContactPage