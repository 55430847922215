import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules'; // Import from modules
import 'swiper/swiper-bundle.css'; // Import Swiper styles

const testimonials = [
  {
    name: 'Sarah J.',
    feedback: 'The team transformed my windows! The team was professional, and my home has never looked better!',
    location: 'Mentor, OH',
  },
  {
    name: 'Michael R.',
    feedback: 'They did an amazing job pressure washing my patio. Quick, efficient, and affordable! Will definitely use them again.',
    location: 'Perry, OH',
  },
  {
    name: 'Emily T.',
    feedback: 'Fantastic service! The team was friendly and did an exceptional job. A+ ',
    location: 'Mentor, OH',
  },
  {
    name: 'David L.',
    feedback: 'My windows looked fantastic! They\'ve exceeded my expectations, and I appreciate their attention to detail.',
    location: 'Madison, OH',
  },
];

const Testimonials = () => {
  return (
    <section className="py-12 bg-gray-200 h-auto"              style={{ 
        backgroundImage: `url('/images/background2.jpg')`, // Replace with your image path
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    }}>
      <div className="container mx-auto text-center text-white-50 lg:pr-20 lg:pl-20 md:pr-2 md:pl-2">
        <h2 className="text-3xl md:text-4xl font-bold mb-8">What Our Customers Say</h2>
        
        <Swiper 
          modules={[Autoplay, Pagination, Navigation]} // Register the modules
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
          navigation
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index} className="bg-white-50 p-10 rounded-lg shadow-lg">
              <p className="text-gray-700 italic mb-4">"{testimonial.feedback}"</p>
              <h4 className="text-xl font-semibold">{testimonial.name}</h4>
              <p className="text-gray-500">{testimonial.location}</p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Testimonials;
