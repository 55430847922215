import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from "axios";

const MessageRespondPage = () => {
    const { id } = useParams(); // Message ID from URL params
    const [message, setMessage] = useState(null); // State to store the fetched message
    const [response, setResponse] = useState(''); // State to store the user's response
    const [username, setUsername] = useState('');
    const navigate = useNavigate();

    // Fetch message data based on the ID
    useEffect(() => {
        const fetchMessageData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/admin/messages/${id}`, {
                    headers: {
                        "Authorization": `Bearer ${Cookies.get('token')}`
                    }
                });
                console.log(response.data);
                setMessage(response.data.message);
            } catch (error) {
                console.error("Error fetching message:", error);
            }
        };

        fetchMessageData();
    }, [id]);

    // Function to handle submitting the response
    const handleResponseSubmit = async (e) => {
        e.preventDefault();

        try {
            const token = Cookies.get('token');
            if (!token) {
                navigate('/admin/login');
                return;
            }
            const res = await axios.post(
                `${process.env.REACT_APP_API_ENDPOINT}/api/admin/messages/respond/${id}`, 
                { response }, 
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                }
            );

            if (res.data.success) {
                alert("Response sent successfully!");
                navigate('/admin/Dashboard');
            } else {
                alert("Failed to send response");
            }
        } catch (error) {
            console.error("Error sending response:", error);
            alert("Error sending response. Please try again.");
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center mt-24 p-8">
            <div className="w-full max-w-4xl bg-white-50 shadow-lg rounded p-8">
                {message ? (
                    <>
                        <h1 className="text-2xl font-bold mb-4">Respond to Message</h1>
                        <p className="text-gray-600"><strong>From:</strong> {message.name} ({message.email})</p>
                        <p className="text-gray-600" style={{ wordWrap: 'break-word', overflowWrap: 'break-word', overflow: 'hidden', maxWidth: '80%' }}>
                            <strong>Message:</strong> {message.message}
                        </p>
                        <p className="text-gray-600 "><strong>Received on:</strong> {new Date(message.createdTime).toLocaleString()}</p>
                        
                        <form onSubmit={handleResponseSubmit} className="mt-6">
                            <textarea
                                className="w-full p-4 border rounded-lg focus:outline-none focus:border-blue-500 resize-none"
                                rows="6"
                                placeholder="Enter your response..."
                                value={response}
                                onChange={(e) => setResponse(e.target.value)}
                                required
                            ></textarea>

                            <button 
                                type="submit" 
                                className="mt-4 bg-blue-600 text-white-50 px-6 py-2 rounded-lg hover:bg-blue-700 transition duration-200"
                            >
                                Send Response
                            </button>
                        </form>
                    </>
                ) : (
                    <p>Loading message data...</p>
                )}
            </div>
        </div>
    );
};

export default MessageRespondPage;
