import React, { useState } from 'react';
import { motion } from 'framer-motion';

const faqData = [
  {
    question: "What services do you offer?",
    answer: "We offer professional pressure washing amd outdoor cleaning services for both residential and commercial properties."
  },
  {
    question: "How often should I get my windows cleaned?",
    answer: "We recommend having your windows cleaned at least twice a year to maintain clarity and shine."
  },
  {
    question: "Are your services eco-friendly?",
    answer: "Yes, we use environmentally friendly cleaning products that are safe for you and the environment."
  },
  {
    question: "Do I need to be home during the service?",
    answer: "It's not necessary for you to be home during the service, but we do recommend it for the best experience."
  },
  {
    question: "How can I get a quote?",
    answer: "You can get a quote by clicking on the 'Get a Quote' button on our website or by contacting us directly."
  },
];

const FAQSection = () => {
  return (
    <section id="faq" className="py-12 bg-gray-100">
      <div className="container mx-auto text-center px-4">
        <h2 className="text-3xl md:text-4xl font-bold mb-8">Frequently Asked Questions</h2>
        <div className="max-w-2xl mx-auto space-y-4">
          {faqData.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    </section>
  );
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b border-gray-300">
      <button
        onClick={toggleDropdown}
        className="flex justify-between items-center w-full py-4 text-left text-gray-800 font-semibold hover:bg-gray-200 transition duration-300 ease-in-out"
      >
        <span className="text-left">{question}</span>
        <span className={`transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}>
          ▼
        </span>
      </button>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: isOpen ? 'auto' : 0, opacity: isOpen ? 1 : 0 }}
        transition={{ duration: 0.3 }}
        className="overflow-hidden"
      >
        <p className="py-2 text-gray-700 text-sm md:text-base">{answer}</p>
      </motion.div>
    </div>
  );
};

export default FAQSection;
