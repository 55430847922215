import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import LoadingSpinner from "../components/LoadingSpinner";

const EditJob = () => {
    const { id } = useParams();
    const [jobData, setJobData] = useState({
        jobName: '',
        name: '',
        address: '',
        phoneNumber: '',
        email: '',
        services: '',
        date: '',
        notes: '',
        status: 'pending'
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchJob = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/admin/jobs/${id}`, {
                    headers: {
                        Authorization: `Bearer ${Cookies.get('token')}`
                    }
                });


                if (response.data.success) {
                    setJobData({
                        jobName: response.data.job.jobName,
                        name: response.data.job.name,
                        address: response.data.job.address,
                        phoneNumber: response.data.job.phoneNumber,
                        email: response.data.job.email,
                        services: response.data.job.services.join(','),
                        date: new Date(response.data.job.date).toISOString().substring(0, 10),
                        notes: response.data.job.notes,
                        status: response.data.job.status
                    });
                }
            } catch (error) {
                console.error('Error fetching job data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchJob();
    }, [id]);

    const handleSubmit = async (e) => {
        
        e.preventDefault();

        if (!jobData.jobName || !jobData.name || !jobData.address || !jobData.phoneNumber || !jobData.services || !jobData.date) {
            setError('Please fill in all required fields.');
            return;
        }

        try {
            const response = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/api/admin/editJob/${id}`, jobData, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`
                }
            });

            if (response.data.success) {
                alert('Job updated successfully!');
                navigate('/admin/dashboard');
            }else{
                alert('Job update Failed!');
                setError('There was a problem updating the job.');
                navigate('/admin/dashboard');
            }
        } catch (error) {
            console.error('Error updating job:', error);
            alert('There was a problem updating the job.');
            setError('There was a problem updating the job.');
            navigate('/admin/dashboard');
        }
    };

    const handleChange = (e) => {
        setJobData({ ...jobData, [e.target.name]: e.target.value });
    };

    return (
        <>{loading ? (
            <div className="min-h-screen flex justify-center flex-col w-full items-center mt-32 mb-12 ">
                <LoadingSpinner />
            </div>
        ) : (   
        <div className="min-h-screen flex justify-center flex-col w-full items-center mt-32 mb-12 ">
            <div className="w-full max-w-md p-6 bg-gray-100 rounded shadow-lg md:max-w-[500px] max-w-[350px]">
                <h2 className="text-2xl font-bold mb-6">Edit Job</h2>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block mb-1 font-semibold">Job Name</label>
                        <input
                            type="text"
                            name="jobName"
                            value={jobData.jobName}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-semibold">Client Name</label>
                        <input
                            type="text"
                            name="name"
                            value={jobData.name}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-semibold">Address</label>
                        <input
                            type="text"
                            name="address"
                            value={jobData.address}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-semibold">Phone Number</label>
                        <input
                            type="tel"
                            name="phoneNumber"
                            value={jobData.phoneNumber}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-semibold">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={jobData.email}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-semibold">Services (comma separated)</label>
                        <input
                            type="text"
                            name="services"
                            value={jobData.services}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-semibold">Date</label>
                        <input
                            type="date"
                            name="date"
                            value={jobData.date}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-semibold">Notes</label>
                        <textarea
                            name="notes"
                            value={jobData.notes}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-semibold">Status</label>
                        <select
                            name="status"
                            value={jobData.status}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded"
                        >
                            <option value="pending">Pending</option>
                            <option value="completed">Completed</option>
                            <option value="canceled">Canceled</option>
                        </select>
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-600 text-white-50 py-2 rounded font-bold"
                    >
                        Update Job
                    </button>
                </form>
            </div>
        </div>
        )};</>
    );
};

export default EditJob;
