// src/components/Footer.js

import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white-50 py-6">
      <div className="container mx-auto text-center">
        <p className="mb-4">
          &copy; {new Date().getFullYear()} Glass and Grit LLC. All rights reserved.
        </p>
        <div className="flex justify-center space-x-4">
          <a href="https://www.facebook.com/people/Glass-Grit-Precision-Cleaning/61567001095045/" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <i className="fab fa-facebook-f text-white-50 hover:text-cerulean-600"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
