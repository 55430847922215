import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { GrView } from "react-icons/gr";
import EditJob from "./editJob";
import LoadingSpinner from "../components/LoadingSpinner";

const ManageJobsPage = ({ onCreateJobClick, onEditJobClick }) => {
    const [jobs, setJobs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    
    const jobsPerPage = 10;

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/admin/jobs?page=${currentPage}`, {
                    headers: {
                        Authorization: `Bearer ${Cookies.get('token')}`,
                    },
                });
                setJobs(response.data.jobs);
                setTotalPages(response.data.totalPages);
            } catch (error) {
                console.error("Error fetching jobs:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchJobs();
    }, [currentPage]);


    const handleDelete = async (jobId) => {
        if (window.confirm("Are you sure you want to delete this job?")) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/api/admin/jobs/${jobId}`, {
                    headers: {
                        Authorization: `Bearer ${Cookies.get('token')}`,
                    },
                });
                setJobs(jobs.filter(job => job._id !== jobId));
            } catch (error) {
                console.error("Error deleting job:", error);
            }
        }
    };

    const editJob = (jobId) => {
        navigate(`/admin/edit-job/${jobId}`);
    };

    const viewJob = (jobId) => {
        navigate(`/admin/view-job/${jobId}`);
    };


    return (
        <>{loading ? (
            <div className="flex justify-center items-center w-full h-full">
                <LoadingSpinner />
            </div>
        ) : (
        <div className="p-8 w-full max-w-4xl bg-gray-100 rounded shadow-lg">
            <h1 className="text-2xl font-bold mb-4">Manage Jobs</h1>
            <button
                className="mb-4 px-4 py-2 bg-blue-600 text-white-50 rounded"
                onClick={onCreateJobClick} // Call the prop function to switch to Create Job page
            >
                Create New Job
            </button>
            <table className="min-w-full bg-white">
                <thead>
                    <tr>
                        <th className="py-2">Job Name</th>
                        <th className="py-2">Date</th>
                        <th className="py-2">Status</th>
                        <th className="py-2">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {jobs.map((job) => (
                        <tr key={job._id} className="border-t">
                            <td className="py-2 truncate">{job.jobName}</td>
                            <td className="py-2">{new Date(job.date).toLocaleDateString()}</td>
                            <td className="py-2">{job.status}</td>
                            <td className="py-2 flex justify-center">
                            <button
                                    className=" px-1 py-1 text-white-50 rounded"
                                    onClick={() => viewJob(job._id)}
                                >
                                    <GrView className="text-green-500" />
                                </button>
                                <button
                                    className=" px-1 py-1 text-white-50 rounded"
                                    onClick={() => editJob(job._id)}
                                >
                                    <CiEdit className="text-blue-500" />
                                </button>
                                <button
                                    className="px-1 py-1 text-white-50 rounded"
                                    onClick={() => handleDelete(job._id)}
                                >
                                    <MdDelete className="text-red-500" />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="flex justify-between mt-4">
                <button
                    className="px-4 py-2 bg-gray-300 rounded"
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <span>
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    className="px-4 py-2 bg-gray-300 rounded"
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>
        </div>
        )}</>
    );
};

export default ManageJobsPage;
