import React from 'react';
import { FaLeaf, FaCheckCircle, FaDollarSign, FaClock } from 'react-icons/fa';
import { motion } from 'framer-motion';

const WhyChooseUs = () => {
  const features = [
    {
      title: 'Professional Expertise',
      description: 'Our team is highly trained and experienced in delivering exceptional cleaning services, ensuring the highest standards of quality.',
      icon: <FaCheckCircle className="text-cerulean-400 text-3xl mb-2" />,
    },
    {
      title: 'Satisfaction Guaranteed',
      description: 'We take pride in our work and strive for complete customer satisfaction. If you’re not happy, we’ll make it right!',
      icon: <FaCheckCircle className="text-cerulean-400 text-3xl mb-2" />,
    },
    {
      title: 'Affordable Pricing',
      description: 'Our competitive pricing ensures you receive the best value without compromising on quality.',
      icon: <FaDollarSign className="text-cerulean-400 text-3xl mb-2" />,
    },
    {
      title: 'Flexible Scheduling',
      description: 'We understand your busy schedule. That’s why we offer flexible booking options to fit your needs.',
      icon: <FaClock className="text-cerulean-400 text-3xl mb-2" />,
    },
  ];

  return (
    <section className="relative py-12 h-full overflow-hidden">
      <div
        className="absolute inset-0 bg-cover bg-center brightness-50"
        style={{
          backgroundImage: "url('/images/collage.webp')",
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></div>

      <div className="relative z-10 text-center max-w-7xl mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold mb-8 text-white-50">Why Choose Us?</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="relative bg-white-50 p-6 rounded-lg shadow-lg hover:shadow-2xl transition-transform transform hover:scale-105 overflow-hidden"
            >
              <motion.div
                className="absolute inset-0 bg-gradient-to-r from-transparent to-white-50 opacity-0 hover:opacity-30 transition-opacity duration-300"
                initial={{ x: '-100%' }}
                animate={{ x: '100%' }}
                transition={{ repeat: Infinity, repeatType: 'reverse', duration: 1.5 }}
              />
              <div className="flex justify-center items-center mb-4">
                {feature.icon}
                <h3 className="text-xl font-semibold px-4">{feature.title}</h3>
              </div>
              <p className="text-gray-700">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
