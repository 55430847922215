import React from "react";
import AnimatedButton from "../components/AnimatedButton";
import ServiceCard from '../components/ServiceCard';
import { FaWindowClose, FaPowerOff, FaLeaf } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';


function ServicesSection() {
    const navigate = useNavigate();

    const services = [
        {
            title: 'Window Cleaning',
            description: 'Let us bring your windows back to life! Our team takes pride in making sure your windows are spotless, using safe, eco-friendly products and techniques that get the job done right. You\'ll be amazed at how much brighter and more inviting your home feels with clean, streak-free windows. It\’s all about giving your place a little extra shine without the hassle.',
            icon: <FaWindowClose />,
            onLearnMore: () => {navigate('/services')}
        },
        {
            title: 'Pressure Washing',
            description: 'Revitalize your property with our top-notch pressure washing services. We effectively remove dirt, grime, and mildew from various surfaces, including driveways, patios, and siding, restoring your home\'s beauty and value. Safe, efficient, and environmentally friendly—let us handle the hard work!',
            icon: <FaPowerOff />,
            onLearnMore: () => {navigate('/services')}
        },
        {
            title: 'Gutter Cleaning',
            description: 'Keep your home protected with our reliable gutter cleaning service. We clear out leaves, debris, and buildup that can lead to costly damage, ensuring your gutters work like they should. It’s one of those jobs no one likes to do—but we’re here to make it easy and stress-free.',
            icon: <FaLeaf />,            onLearnMore: () => {navigate('/services')}
        },
        {
            title: 'Deck and Patio Cleaning',
            description: 'Give your outdoor space a fresh start with our deck and patio cleaning service. We’ll get rid of dirt, grime, and weather buildup, leaving your deck or patio looking like new. Whether you’re hosting a BBQ or just want to enjoy your backyard, we’ve got you covered—no hassle, just great results.',
            icon: <FaLeaf />,
            onLearnMore: () => {navigate('/services')}
        },
    ];

    return (
        <div id="services" className="h-auto w-full flex-col justify-center align-middle" 
             style={{ 
                 backgroundImage: `url('/images/populationbackground.jpg')`, // Replace with your image path
                 backgroundSize: 'cover',
                 backgroundPosition: 'center',
                 backgroundRepeat: 'no-repeat',
             }}>
            <div className="text-center p-2 pt-6 bg-white bg-opacity-75 rounded-lg shadow-lg">
                <h1 className="text-4xl font-bold text-gray-800 mb-4">Our Services</h1>
                <h3 className="text-xl text-gray-600">Get a streak-free shine for your windows inside and out.</h3>
            </div>
            <div className="w-1/2 mx-auto my-4 border-t border-gray-300"></div>
            <div className="flex flex-wrap justify-center gap-6 p-6">
                {services.map((service, index) => (
                    <ServiceCard
                        key={index}
                        title={service.title}
                        description={service.description}
                        icon={service.icon}
                        onLearnMore={service.onLearnMore}
                    />
                ))}
            </div>
        </div>
    );
}

export default ServicesSection;
