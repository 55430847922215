import React from 'react'; 
import { FaTrash } from 'react-icons/fa';
import Cookies from 'js-cookie';
import axios from "axios";
import { useNavigate } from "react-router-dom";

const MessageCard = ({ name, email, message, phone, date, id, services, onDelete, responded }) => {
    const token = Cookies.get('token');
    const Services = services.join(', ');
    const navigate = useNavigate();
    

    const handleDelete = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/admin/delete/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.data.success) {
                onDelete(id);
            }
        } catch (error) {
            console.error("Error deleting the message:", error);
        }
    };

    return (
        <div className="max-w-md mx-auto bg-white shadow-md rounded-lg overflow-hidden my-4 border border-gray-200">
            <div className="p-4">
                <div className="flex justify-between items-center mb-2">
                    <div className="flex flex-col">
                        <h3 className="text-lg font-semibold text-gray-800">{name}</h3>
                        <span className="text-sm text-gray-600">{email}</span>
                        <span className="text-sm text-gray-600">{phone}</span>
                    </div>
                    <div className="flex flex-col">
                        <button
                            className="text-red-500 hover:text-red-600 transition duration-200 flex justify-center items-center mb-2"
                            onClick={handleDelete}
                        >
                            <FaTrash size={25} />
                        </button>
                        <span className="text-sm text-gray-500">{new Date(date).toLocaleDateString()}</span>
                    </div>
                </div>
                <p className="text-gray-700 mb-4 truncate">{message}</p>
                <p className="text-gray-700 mb-4">Services: {Services} </p>
                <button
                    className="w-full bg-blue-500 text-white-50 font-bold py-2 rounded-lg hover:bg-blue-600 transition duration-200"
                    onClick={() => navigate(`/admin/messages/${id}`)}
                >
                    Respond
                </button>
                <p className={`text-sm {text-green-500} ${responded ? 'text-green-500' : 'text-red-500'} text-center w-full mt-2`}>{responded ? 'Responded' : 'Not responded'}</p>

            </div>
        </div>
    );
};

export default MessageCard;
