import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import MessageList from "../components/MessageList";
import ManageJobsPage from "./ManageJobsPage";
import CreateJob from "./createJob";
import LoadingSpinner from "../components/LoadingSpinner";

function Dashboard() {
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [username, setUsername] = useState('');
    const [selectedPage, setSelectedPage] = useState('dashboard'); // Default page is 'dashboard'
    const [loading, setLoading] = useState(true); // Loading state

    const renderPage = () => {
        switch (selectedPage) {
          case 'dashboard':
            return <MessageList messages={messages} />;
          case 'manageJobs':
            return <ManageJobsPage onCreateJobClick={() => setSelectedPage('createJob')} />;
          case 'createJob':
            return <CreateJob />;
          case 'settings':
            return <div>Settings</div>; 
          default:
            return <MessageList messages={messages} />;
        }
    };

    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                setLoading(true); // Start loading
                const token = Cookies.get('token');
                if (!token) {
                    navigate('/admin/login');
                    return;
                }
                const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/admin/dashboard`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                console.log(response.data);

                if (response.data.success) {
                    setMessages(response.data.messages);
                    setUsername(response.data.user.username);
                } else {
                    console.error('Error fetching dashboard data:', response.data.message);
                }
            } catch (error) {
                console.error('Error during dashboard data fetch:', error);
            } finally {
                setLoading(false); // End loading
            }
        };

        fetchDashboardData();
    }, []);

    const handleLogout = async () => {  
        await Cookies.remove('token');
        navigate('/admin/login');
    };

    return (
        <div className="w-full h-auto mt-24 flex flex-col md:flex-row">
            <div className="flex flex-col md:h-[80vh] h-auto bg-white-50 bg-white-300 shadow-lg w-full md:w-[200px]">
                <div className="flex flex-col justify-between bg-white-100 p-4 h-full">
                    <div>
                        <h1 className="text-2xl font-bold mb-2">Welcome,</h1>
                        <h3 className="text-lg text-gray-700 mb-6">{username}</h3>
                        <ul className="space-y-4 my-4">
                            <li>
                                <button className={`w-full py-2 text-left bg-blue-500 text-white-50 text-center font-bold hover:bg-blue-600 transition duration-200 ${selectedPage === 'dashboard' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`} onClick={() => setSelectedPage('dashboard')}>
                                    Dashboard
                                </button>
                            </li>
                            <li>
                                <button className={`w-full py-2 text-left bg-blue-500 text-white-50 text-center font-bold hover:bg-blue-600 transition duration-200 ${selectedPage === 'manageJobs' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`} onClick={() => setSelectedPage('manageJobs')}>
                                    Manage Jobs
                                </button>
                            </li>
                            <li>
                                <button className={`w-full py-2 text-left bg-blue-500 text-white-50 text-center font-bold hover:bg-blue-600 transition duration-200 ${selectedPage === 'settings' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`} onClick={() => setSelectedPage('settings')}>
                                    Settings
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <button className="w-full py-2 text-left bg-red-500 text-white-50 text-center font-bold hover:bg-red-600 transition duration-200" onClick={handleLogout}>
                            Logout
                        </button>
                    </div>
                </div>
            </div>
            <div className="md:h-full w-1 bg-cerulean-600"></div>
            <div className="w-full md:max-h-full min-h-[80vh] h-auto flex flex-row md:justify-start justify-center px-4 py-4 overflow-auto">
                {loading ? (
                    <div className="flex justify-center items-center w-full h-full">
                        <LoadingSpinner />
                    </div>
                ) : (
                    renderPage()
                )}
            </div>
        </div>
    );
}

export default Dashboard;
