import React from "react";

function WindowCleaningPage() {
    return (
        <div>
            <h1>WindowCleaningPage</h1>
        </div>
    );
}   

export default WindowCleaningPage