import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import validator from "validator";

const ContactForm = () => {
    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [Phone, setPhoneNumber] = useState('');
    const [Message, setMessage] = useState('');
    const [WindowCleaning, setWindowCleaning] = useState(false);
    const [PressureWashing, setPressureWashing] = useState(false);
    const [GutterCleaning, setGutterCleaning] = useState(false);
    const [DeckCleaning, setDeckCleaning] = useState(false);
    const [IsSuccess, setIsSuccess] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [characterCount, setCharacterCount] = useState(0);
    const [disableButton, setDisableButton] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
          });
        }, []);
    
    useEffect(() => {
        if (responseMessage) {
            setTimeout(() => {
                setResponseMessage('');
            }, 3000);
        }
    }, [responseMessage]);

    useEffect(() => {
        if (IsSuccess) {
            setTimeout(() => {
                setIsSuccess(false);
                navigate('/');
            }, 5000);
        }
        
    }, [IsSuccess, navigate]);

    useEffect(() => {
        setCharacterCount(Message.length);
    }, [Message]);

    const handleSubmit = async () => {
        if (disableButton) {
            setResponseMessage('Please wait...');
            return;
        }
        setDisableButton(true);
        try {

            const serviceOptions = [
                { checked: WindowCleaning, name: 'Window Cleaning' },
                { checked: PressureWashing, name: 'Pressure Washing' },
                { checked: GutterCleaning, name: 'Gutter Cleaning' },
                { checked: DeckCleaning, name: 'Deck Cleaning' }
            ];
            const Services = serviceOptions
                .filter(option => option.checked)
                .map(option => option.name);

            if (validator.isEmail(Email) === false) {
                setIsSuccess(false);
                setResponseMessage('Please enter a valid email address.');
                setDisableButton(false);
                return;
            }
            if (Message.length > 200) {
                setIsSuccess(false);
                setResponseMessage('Message must be less than 200 characters.');
                setDisableButton(false);
                return;
            }
            if (!Name || !Email || !Phone || !Message) {
                setIsSuccess(false);
                setResponseMessage('Please fill in all required fields.');
                setDisableButton(false);
                return;
            }      
            if (Services.length === 0) {
                setIsSuccess(false);
                setResponseMessage('Please select at least one service.');
                setDisableButton(false);
                return;
            }   
            const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/contact`, { name: Name, email: Email, phoneNumber: Phone, message: Message, services: Services });
            if (response.status === 201) {
                setIsSuccess(true);
                setResponseMessage('Message sent successfully! You will be contacted shortly.');
            }else{
                console.log(response);
                setIsSuccess(false);
                setResponseMessage('Message not sent!');
            }
        } catch (error) {
            console.error(error);
            setIsSuccess(false);
            setDisableButton(false);
            setResponseMessage('Message not sent!');
        }
    }

    return (
        <div className="mt-12 w-1/2 bg-white-50 h-full min-h-[760px] min-w-[380px] flex flex-col rounded hover:shadow-xl hover:scale-105 transition duration-200 ">
            <h2 className="text-3xl font-bold mb-4 text-center pt-4">Contact Us</h2>
            <div className="h-1 w-full bg-cerulean-600"></div>
            <div className="flex flex-col justify-center items-center mt-4">
                <input id="name" type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} className="p-2 w-3/4 bg-white-50 mb-4 rounded border-2"/>
                <input id="email" type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} className="p-2 w-3/4 bg-white-50 mb-4 rounded border-2"/>
                <input id="phone" type="phone" placeholder="Phone Number: (123) 456-7890" onChange={(e) => setPhoneNumber(e.target.value)} className="p-2 w-3/4 bg-white-50 mb-4 rounded border-2"/>
                <div className="w-3/4 mb-4" >
                    <h3 className="text-lg font-bold text-gray-600 mb-2 text-center">I'm interested in</h3>
                    <div className="mr-4">
                        <input id="WindowCleaning" type="checkbox" className="mr-2" onChange={(e) => setWindowCleaning(e.target.checked)} />
                        <label htmlFor="WindowCleaning" className="mr-2">Window Cleaning</label>
                        <input id="PressureWashing" type="checkbox" onChange={(e) => setPressureWashing(e.target.checked)} className="mr-2"/>
                        <label htmlFor="PressureWashing">Pressure Washing</label>
                    </div>
                    <div>
                        <input id="GutterCleaning" type="checkbox" className="mr-2" onChange={(e) => setGutterCleaning(e.target.checked)}/>
                        <label htmlFor="GutterCleaning" className="mr-5">Gutter Cleaning</label>
                        <input id="DeckCleaning" type="checkbox" className="mr-2" onChange={(e) => setDeckCleaning(e.target.checked)}/>
                        <label htmlFor="DeckCleaning">Deck Cleaning</label>
                    </div>
                </div>
                <textarea id="message" type="text" placeholder="Message" onChange={(e) => setMessage(e.target.value)} className="p-2 h-60 w-3/4 bg-white-50 mb-4 rounded resize-none border-2"/>
                <p className="text-gray-600 mb-4">{characterCount}/200</p>
                <button onClick={() => handleSubmit()} className="bg-cerulean-600 text-white-50 font-bold py-3 px-6 rounded w-3/4 hover:bg-cerulean-700" {...disableButton && 'disabled'}>Submit</button>
            </div>
            {responseMessage && (
                <div className={`mt-4 p-2 text-center text-white font-bold ${IsSuccess ? 'bg-green-500' : 'bg-red-500'} rounded`}>
                    {responseMessage}
                </div>
            )}
        </div>
    )
}

export default ContactForm;