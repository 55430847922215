import React from "react";

function PressureWashingPage() {
    return (
        <div>
            <h1>PressureWashingPage</h1>
        </div>
    );
}   

export default PressureWashingPage