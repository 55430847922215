import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from 'js-cookie'; 
import { useNavigate } from "react-router-dom"; // for redirection

const LoginPage = () => {
    const [message, setMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const navigate = useNavigate(); // useNavigate hook for redirection

    const HandleLogin = async () => {
        const username = document.getElementById('username').value;
        const password = document.getElementById('password').value;

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/admin/login`, { username, password });
            
            if (response.data.success) {
                const token = response.data.token;
                Cookies.set('token', token, { expires: 7 }); 
                setMessage(response.data.message || 'Login successful! Redirecting...');
                setIsSuccess(true); // Mark as success for redirect

                console.log('Logged in successfully! Token stored in cookies.');
            } else {
                setMessage(response.data.message || 'Login failed.');
                setIsSuccess(false);
            }
        } catch (error) {
            setMessage('Error during login.');
            setIsSuccess(false);
            console.error('Error during login:', error);
        }
    }

    // Effect to handle redirection after 5 seconds if login is successful
    useEffect(() => {
        if (isSuccess) {
            const timer = setTimeout(() => {
                navigate('/admin/dashboard'); // Redirect to the dashboard
            }, 5000);
            return () => clearTimeout(timer); // Cleanup the timer on component unmount
        }
    }, [isSuccess, navigate]);

    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            navigate('/admin/dashboard');
        }
    }, [navigate]);

    return (
        <div className="min-h-screen bg-white-50 flex flex-col justify-start items-center mt-24 pb-12" 
            style={{
                backgroundImage: `url('/images/populationbackground.jpg')`, 
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}>
            <div className="mt-12 w-1/2 bg-white-50 h-full min-h-[300px] min-w-[380px] flex flex-col rounded hover:shadow-xl hover:scale-105 transition duration-200 ">
                <h2 className="text-3xl font-bold mb-4 text-center pt-4">Login</h2>
                <div className="h-1 w-full bg-cerulean-600"></div>
                <div className="flex flex-col justify-center items-center mt-4">
                    <input id="username" type="text" placeholder="Username" className="p-2 w-3/4 bg-white-50 mb-4 rounded border-2"/>
                    <input id="password" type="password" placeholder="Password" className="p-2 w-3/4 bg-white-50 mb-4 rounded border-2"/>
                    <button onClick={HandleLogin} className="bg-cerulean-600 text-white-50 font-bold py-3 px-6 rounded w-3/4 hover:bg-cerulean-700 transition duration-200">Login</button>
                </div>
                {message && (
                    <div className={`mt-4 p-2 text-center text-white font-bold ${isSuccess ? 'bg-green-500' : 'bg-red-500'} rounded`}>
                        {message}
                    </div>
                )}
            </div>
        </div>
    );
};

export default LoginPage;
