import React, { useState, useEffect } from "react";
import MessageCard from "./MessageCard";
import axios from "axios";
import Cookies from 'js-cookie';
import { Navigate } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";

const MessageList = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const messagesPerPage = 2;
    const token = Cookies.get('token');

    // Sort messages by createdTime in descending order
    const sortedMessages = [...messages].sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime));

    // Calculate pagination details
    const indexOfLastMessage = currentPage * messagesPerPage;
    const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
    const currentMessages = sortedMessages.slice(indexOfFirstMessage, indexOfLastMessage);

    // Handle page navigation
    const totalPages = Math.ceil(messages.length / messagesPerPage);

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const handleDeleteMessage = (id) => {
        setMessages(messages.filter(message => message._id !== id));
    };



    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/admin/dashboard`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setMessages(response.data.messages);
            } catch (error) {
                console.error('Error fetching messages:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMessages();
    }, []);


    return (
        <>{loading ? (
            <div className="flex justify-center items-center w-full h-full">
                <LoadingSpinner />
            </div>
        ) : (
        <div className="px-4">
            <ul>
                {currentMessages.length > 0 ? (
                    currentMessages.map((msg, index) => (
                        <li key={index}>
                            <MessageCard
                                name={msg.name}
                                email={msg.email}
                                message={msg.message}
                                date={msg.createdTime}
                                phone={msg.phoneNumber}
                                id={msg._id}
                                services={msg.services}
                                responded={msg.responded}
                                onDelete={handleDeleteMessage}
                            />
                        </li>
                    ))
                ) : (
                    <p>No messages found.</p>
                )}
            </ul>

            {/* Pagination controls */}
            {messages.length !== 0 && (
            <div className="flex justify-center mt-4">
                <button
                    onClick={goToPreviousPage}
                    className={`mr-2 px-4 py-2 bg-blue-500 text-white-50 rounded ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <span className="px-4 py-2">{`Page ${currentPage} of ${totalPages}`}</span>
                <button
                    onClick={goToNextPage}
                    className={`ml-2 px-4 py-2 bg-blue-500 text-white-50 rounded ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>
              )}
        </div>
        )}</>
    );
};

export default MessageList;
