import React, { useEffect } from "react";
import HeroSection from "../components/HeroSection";
import ServicesSection from "../components/ServicesSection";
import WhyChooseUs from "../components/ChooseUs";
import Testimonials from "../components/TestimonialsSection";
import FAQSection from "../components/FAQsSection";
import '../styles/HomePage.css';

function HomePage() {
    useEffect(() => {
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
          });
        }, []);
    return (
        <div className="min-h-screen bg-gray-100 flex-col mt-24">
            <HeroSection />
            <div className="h-1 bg-cerulean-600" id="shimmer"></div>
            <ServicesSection />
            <div className="h-1 bg-cerulean-600" id="shimmer"></div>
            <WhyChooseUs />
            <div className="h-1 bg-cerulean-600" id="shimmer"></div>
            <Testimonials />
            <div className="h-1 bg-cerulean-600" id="shimmer"></div>
            <FAQSection />
        </div>
      );
}   

export default HomePage