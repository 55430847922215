import React from 'react';
import { motion } from 'framer-motion';

const AnimatedButton = ({text, className}) => {
  return (
    <motion.button
      className={className || "bg-cerulean-600 text-white font-bold px-4 py-2 rounded"}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      {text || 'Button'}
    </motion.button>
  );
};

export default AnimatedButton;
