import React, {useEffect} from "react";
import ServiceCard from "../components/ServiceCard";
import { FaWindowClose, FaPowerOff, FaLeaf } from "react-icons/fa";
import Slider from "react-slick"; // Make sure to install react-slick and slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const ServicesPage = () => {
  useEffect(() => {
    window.scroll({
        top: 0, 
        left: 0, 
        behavior: 'smooth'
      });
    }, []);
      
  const services = [
    {
      title: "Window Cleaning",
      description:
        "Experience crystal-clear views with our professional window cleaning services. Our expert team uses eco-friendly products and advanced techniques to ensure your windows sparkle inside and out.",
      icon: <FaWindowClose className="text-cerulean-400 text-3xl mb-2" />,
      images: [
        "/images/window1.jpg",
        "/images/window2.jpg",
        "/images/window3.jpg",
      ],
    },
    {
      title: "Pressure Washing",
      description:
        "Revitalize your property with our top-notch pressure washing services. We effectively remove dirt, grime, and mildew from various surfaces, restoring your home's beauty.",
      icon: <FaPowerOff className="text-cerulean-400 text-3xl mb-2" />,
      images: [
        "/images/pressurewashing1.jpg",
        "/images/pressurewashing2.jpg",
        "/images/pressurewashing3.webp",
      ],
    },
    {
      title: "Gutter Cleaning",
      description:
        "Prevent water damage with clean, flowing gutters. Our gutter cleaning service ensures that your home is protected from potential leaks and structural damage.",
      icon: <FaLeaf className="text-cerulean-400 text-3xl mb-2" />,
      images: [
        "/images/guttercleaning1.jpg",
        "/images/guttercleaning2.jpg",
        "/images/guttercleaning3.jpg",
      ],
    },
    {
      title: "Deck and Patio Cleaning",
      description:
        "Give your outdoor space a fresh start with our deck and patio cleaning service. We’ll get rid of dirt, grime, and weather buildup, leaving your deck or patio looking like new. Whether you’re hosting a BBQ or just want to enjoy your backyard, we’ve got you covered—no hassle, just great results.",
      icon: <FaLeaf className="text-cerulean-400 text-3xl mb-2" />,
      images: [
        "/images/guttercleaning1.jpg",
        "/images/guttercleaning2.jpg",
        "/images/guttercleaning3.jpg",
      ],
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="w-full px-8 py-12 h-auto min-h-screen flex flex-col align-middle justify-start mt-24 bg-gray-100" style={{ 
                 backgroundImage: `url('/images/populationbackground.jpg')`,
                 backgroundSize: 'cover',
                 backgroundPosition: 'center',
                 backgroundRepeat: 'no-repeat',
             }}>

      <h1 className="text-4xl font-bold text-center text-gray-800  mb-16">Our Services</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {services.map((service, index) => (
          <div
            key={index}
            className="bg-white-50 p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105"
          >
            <div className="flex justify-center mb-4">{service.icon}</div>
            <h2 className="text-2xl font-semibold text-center mb-4">
              {service.title}
            </h2>
            <p className="text-gray-700 mb-4">{service.description}</p>
            <Slider {...settings}>
              {service.images.map((image, idx) => (
                <div key={idx}>
                  <img
                    src={image}
                    alt={`${service.title} ${idx + 1}`}
                    className="w-full h-48 object-cover rounded"
                  />
                </div>
              ))}
            </Slider>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesPage;
