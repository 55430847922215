import React ,{useEffect} from "react";


const PricePage = () => {
    useEffect(() => {
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
          });
        }, []);

    return (
        <div className="w-full px-8 py-12 h-auto min-h-screen flex flex-col align-middle justify-start mt-24 bg-gray-100" style={{ 
            backgroundImage: `url('/images/populationbackground.jpg')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
        }}>
        <div className="rounded-lg shadow-lg p-8 bg-white-50">
        <div className="bg-black rounded-lg shadow-lg p-8 opacity-60 mb-8">
            <h1 className="text-4xl font-bold text-center text-white-50 mb-4">Price Guide</h1>
            <h4 className="text-1xl font-bold text-center text-white-50">We like to be transparent and fair when giving quotes. Keep in mind this is just a price guide, not a final price. We base our pricing guide on national and state averages for the industry.</h4>
        </div>
        <div className="overflow-x-auto">
            <table className="table-auto w-full bg-white-50 rounded-lg shadow-lg border border-gray-800">
            <thead>
                <tr className="bg-gray-800 text-white-50">
                <th className="px-4 py-2">Service</th>
                <th className="px-4 py-2">Price Range</th>
                </tr>
            </thead>
            <tbody>
                <tr className="bg-gray-100 text-gray-800">
                <td className="border px-4 py-2">Driveways, Sidewalks, Decks</td>
                <td className="border px-4 py-2">.30 - .60 cents per sq. ft.</td>
                </tr>
                <tr className="bg-white-50 text-gray-800">
                <td className="border px-4 py-2">Vinyl Siding</td>
                <td className="border px-4 py-2">.20 - .25 cents per sq. ft.</td>
                </tr>
                <tr className="bg-gray-100 text-gray-800">
                <td className="border px-4 py-2">Brick</td>
                <td className="border px-4 py-2">.22 - .27 cents per sq. ft.</td>
                </tr>
                <tr className="bg-white-50 text-gray-800">
                <td className="border px-4 py-2">Gutter Cleaning (1 story)</td>
                <td className="border px-4 py-2">$150</td>
                </tr>
                <tr className="bg-gray-100 text-gray-800">
                <td className="border px-4 py-2">Gutter Cleaning (2 story)</td>
                <td className="border px-4 py-2">$250</td>
                </tr>
                <tr className="bg-white-50 text-gray-800">
                <td className="border px-4 py-2">Window Cleaning</td>
                <td className="border px-4 py-2">Starting at $150</td>
                </tr>
                <tr className="bg-gray-100 text-gray-800">
                <td className="border px-4 py-2">Per Pane (Windows)</td>
                <td className="border px-4 py-2">$4 - $7 per pane</td>
                </tr>
            </tbody>
            </table>
        </div>

        <h2 className="text-3xl font-semibold mt-12 mb-4 bg-black text-center text-white-50 opacity-60 rounded-lg shadow-lg p-4">Add-On Services</h2>
        <div className="overflow-x-auto">
            <table className="table-auto w-full bg-white=50 rounded-lg shadow-lg">
            <thead>
                <tr className="bg-gray-800 text-white-50">
                <th className="px-4 py-2">Add-On</th>
                <th className="px-4 py-2">Price Range</th>
                </tr>
            </thead>
            <tbody>
                <tr className="bg-gray-100 text-gray-800">
                <td className="border px-4 py-2">Driveway Pretreatment/Post-treatment</td>
                <td className="border px-4 py-2">$25 - $50</td>
                </tr>
                <tr className="bg-white-50 text-gray-800">
                <td className="border px-4 py-2">Oil and Rust Stain Remover</td>
                <td className="border px-4 py-2">$25 - $50</td>
                </tr>
            </tbody>
            </table>
        </div>

        <p className="text-gray-600 text-center mt-6">*Prices may vary depending on the severity of the job. Please contact us for an exact quote.</p>
        </div>
        </div>
    );
};

export default PricePage