import React from 'react';

const ServiceCard = ({ title, description, icon, onLearnMore }) => {
  return (
    <div className="max-w-sm max-h-96 rounded overflow-hidden shadow-lg p-6 bg-white-50 transition-transform duration-200 hover:scale-105">
      <div className="flex items-center mb-4">
        <div className="text-cerulean-600 text-4xl mr-4">{icon}</div>
        <h2 className="text-xl font-bold text-gray-800">{title}</h2>
      </div>
      <p className="text-gray-600 mb-4">{description}</p>
      <button 
        onClick={onLearnMore}
        className="bg-cerulean-600 text-white-50 py-2 px-4 rounded hover:bg-cerulean-700 transition duration-200"
      >
        Learn More
      </button>
    </div>
  );
};

export default ServiceCard;
