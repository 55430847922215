import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const CreateJob = ({ onBack }) => {
    const [jobName, setJobName] = useState('');
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [services, setServices] = useState('');
    const [date, setDate] = useState('');
    const [notes, setNotes] = useState('');
    const [error, setError] = useState('');
    
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!jobName || !name || !address || !phoneNumber || !services || !date) {
            setError('Please fill in all required fields.');
            return;
        }
        
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/admin/createJob`, {
                jobName,
                name,
                address,
                phoneNumber,
                email,
                services: services.split(','), // Services entered as comma-separated values
                date,
                notes,
            }, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                },
            });
            
            if (response.data.success) {
                alert('Job created successfully!');
                window.location.reload();
            } else {
                setError(response.data.message);
                console.error('Error creating job:', response.data.message);
                window.location.reload();
            }
        } catch (error) {
            console.error('Error creating job:', error);
            setError('There was a problem creating the job.');
        }
    };

    return (
        <div className="min-h-screen flex justify-center flex-col w-full items-center">
            <div className="w-full max-w-md  p-6 bg-gray-100 rounded shadow-lg">
                <h2 className="text-2xl font-bold mb-6">Create a New Job</h2>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block mb-1 font-semibold">Job Name</label>
                        <input
                            type="text"
                            value={jobName}
                            onChange={(e) => setJobName(e.target.value)}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-semibold">Client Name</label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-semibold">Address</label>
                        <input
                            type="text"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-semibold">Phone Number</label>
                        <input
                            type="tel"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-semibold">Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full px-3 py-2 border rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-semibold">Services (comma separated)</label>
                        <input
                            type="text"
                            value={services}
                            onChange={(e) => setServices(e.target.value)}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-semibold">Date</label>
                        <input
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            className="w-full px-3 py-2 border rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-semibold">Notes</label>
                        <textarea
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            className="w-full px-3 py-2 border rounded"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-600 text-white-50 py-2 rounded font-bold"
                    >
                        Create Job
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CreateJob;
